import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/components/mdxPageLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Based on “The Bro Code”, written by the awesome Barney Stinson.`}</p>
    <p>{`Containing approximately 150 “unspoken” rules, this code of conduct ranges from the simple (bros before hos) to the complex (the hot-to-crazy ratio, complete with bar graphs and charts).`}</p>
    <p><a parentName="p" {...{
        "href": "http://windowsphone.com/s?appid=2f575845-7f15-4de2-acc8-cb68bcf954a7"
      }}><span parentName="a" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "258px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "26.111111111111107%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAYAAABFA8wzAAAACXBIWXMAAAsTAAALEwEAmpwYAAABGklEQVQY02VQy0rDUBDNZ7jw0apUtL6g4gPc+AIVrC/U+jN+h7+gC/fSNImJpGmS7l26Ke7uY6BYg94jk2QhuDjMnHtnzpwZq2qLrGILrPnKjD0LcD5tC0y0C0yWcbwtMNORmCo51zH/C+6zKrbIbvuEh8HI3L9/YtWTOI0J5wnhKNK4SQnHkUYrJex3NZo9wllM2H5V+bB/gg1fZU8fX7h7G5rht8FhpNF4UdgJNTYDlTdyZL4VKKz7ChuBwpInMe8UItVyKxa16q7M2NnjYGRk9oODrsZFQrhOCc2Y0OpTzndDnb+xe+YnPcJlQrhKixoexuew+IZzjsReqA2vWHMkFl2JuitzFyslOJ/tFHHZK/4XyhpGrXT7C9G2Jp17BpstAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Download",
            "title": "Download",
            "src": "/static/3e5987e0fe49f57b7101f5af746261ba/d9489/258x67_WPS_Download_cyan.png",
            "srcSet": ["/static/3e5987e0fe49f57b7101f5af746261ba/e9ff0/258x67_WPS_Download_cyan.png 180w", "/static/3e5987e0fe49f57b7101f5af746261ba/d9489/258x67_WPS_Download_cyan.png 258w"],
            "sizes": "(max-width: 258px) 100vw, 258px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span></a></p>
    <h2>{`Source code`}</h2>
    <p>{`Available in `}<a parentName="p" {...{
        "href": "https://github.com/pedrolamas/PedroLamas.TheBroCode"
      }}>{`GitHub`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      